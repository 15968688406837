<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('signup.title') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps">
              <span class="current">{{ $t('signup.step') }} 1</span>
              <span class="total">{{$t('signup.out-of') }} 3</span>
            </div>
            <div class="signup-by-iin-main">
              <div class="iin-left">
                <div class="teachers-subjects-wrapper">
                  <p class="teachers-subjects-item" style="margin-bottom: 15px" v-html="$t('teachers_can_register.top-text')" />
                  <p class="teachers-subjects-item" v-for="item in $t('teachers_can_register.list')" v-html="item" />
                  <p class="teachers-subjects-item" style="margin-top: 15px" v-html="$t('teachers_can_register.bottom-text')" />
                </div>
<!--                <div class="intstruction-wrapper">-->
<!--                  <span class="instruction-title">{{ $t('signup-by-iin.instruction') }}</span>-->
<!--                  <a-->
<!--                      v-if="$i18n.locale=='ru'"-->
<!--                      download-->
<!--                      href="/files/ru/Инструкция_по_регистрации_Учителей_Мын_бала.pdf"-->
<!--                      class="download-instruction"-->
<!--                  >-->
<!--                    {{ $t('signup-by-iin.download-instruction') }}-->
<!--                  </a>-->
<!--                  <a-->
<!--                      v-if="$i18n.locale=='kz'"-->
<!--                      download-->
<!--                      href="/files/kz/Инструкция_по_регистрации_Учителей_Мын_бала_каз.pdf"-->
<!--                      class="download-instruction"-->
<!--                  >-->
<!--                    {{$t('signup-by-iin.download-instruction')}}-->
<!--                  </a>-->
<!--                  <button @click="$bvModal.show('youtube-modal')" class="watch-instruction">-->
<!--                    {{ $t('signup-by-iin.see-instruction') }}-->
<!--                  </button>-->
<!--                </div>-->
                <form-group
                    class="iin-input"
                    :field="$v.teacher.iin"
                    :label="$t('signup.iin-label') + $t('references.required-field')"
                    name="iin"
                >
                  <el-input
                      clearable
                      maxlength="12"
                      :placeholder="$t('signup.iin-label')"
                      v-model="teacher.iin"
                      @change="$v.teacher.iin.$touch()"
                      show-word-limit
                  >
                  </el-input>
                </form-group>
                <form-group
                    class="iin-input"
                    :field="$v.teacher.email"
                    :label="$t('references.email-label') + $t('references.required-field')"
                    name="email"
                >
                  <el-input
                      clearable
                      :placeholder="$t('references.email-placeholder')"
                      v-model="teacher.email"
                      @change="$v.teacher.iin.$touch()"
                  >
                  </el-input>
                </form-group>
                <div class="row">
                  <div class="col-12">
                    <router-link to="/signin-teacher" tag="el-link" style="text-decoration: none">
                      {{ $t('signin-form.have-account') }} <span class="text-primary">{{ $t('signin-form.signin-button') }}</span>
                    </router-link>
                  </div>
                </div>
                <div class="button-iin-signup-group">
                  <el-button type="button" class="button-iin-next" @click="submit">
                    {{ $t('signup.next-btn') }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="youtube-modal" hide-footer hide-header>
      <div id="error-wrapper">
        <div id="dialog">

          <button @click="$bvModal.hide('youtube-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <iframe style="width: 100%; height: 500px;" v-if="$i18n.locale=='ru'"
                  src="https://www.youtube.com/embed/12DxeHCSlJw" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
          <iframe style="width: 100%; height: 500px;" v-else-if="$i18n.locale=='kz'"
                  src="https://www.youtube.com/embed/EJoTE-nbHN0" title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>

        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { minLength, required, email } from "vuelidate/lib/validators"

export default {
  name: 'TeacherSignupStep1',
  data() {
    return {
      teacher: {
        iin: '',
        email: '',
      }
    }
  },
  validations: {
    teacher: {
      iin: { required, minLength: minLength(12) },
      email: { required, email },
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$http.get(`${window.API_ROOT}/api/teacher/register/first-step`, {
        params: {
          iin: this.teacher.iin,
          email: this.teacher.email,
        }
      })
          .then((res) => {
            console.log(res);
            localStorage.setItem('teacherSignUpData', JSON.stringify(res.body.teacher));

            this.$router.push({ name: 'teacher-signup-second-step' });
          })
          .catch((e) => {
            console.log(e);
            Vue.toastr({
              message: this.$t(`signup.${e.body.message}`),
              type: 'error'
            });
          });
    },
  },
  mounted() {
    if (sessionStorage.getItem('teacherIIN')) {
      this.teacher.iin = JSON.parse(sessionStorage.getItem('teacherIIN'));
    }
  }
}
</script>

<style lang="css" scoped>
.modal-dialog {
  max-width: max-content;
  width: max-content;
}

@media screen and (max-width: 991px) {
  .signup-by-iin-main {
    flex-direction: column;
  }

  .iin-left {
    width: 100%;
  }

  .iin-info {
    width: 100%;
  }

  .iin-wrap-info {
    height: 482px;
  }

  .button-iin-signup-group {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575px) {
  .iin-input {
    max-width: unset;
  }

  .download-instruction {
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .watch-instruction {
    width: 100%;
    margin-left: 0;
  }

  .button-iin-signup-group {
    flex-direction: column;
  }

  .button-iin-next {
    margin-right: 0;
    display: block;
    width: 100%;
  }

  .button-iin-clear {
    margin-bottom: 1rem;
    width: 100%;
    display: block;
  }

  .iin-search-item {
    font-size: 14px;
  }

  .iin-info {
    padding: 10px;
  }

  .warning-message {
    width: 64%;
  }
}
</style>

